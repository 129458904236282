











































import { Component, Vue, Watch } from "vue-property-decorator";

import BaseTooltip from "@/components/menu/BaseTooltip.vue";
import SwitchSlider from "@/components/menu/SwitchSlider.vue";
import { State } from "@/store/settings";

@Component({
  components: {
    BaseTooltip,
    SwitchSlider,
  },
})
export default class SettingsNormal extends Vue {
  settings: State = { ...this.$store.state.settings };

  get drag(): boolean {
    return this.$store.state.drag;
  }

  get editItems(): boolean {
    return this.$store.state.edit;
  }

  @Watch("settings", { deep: true })
  onSettingsChanged(): void {
    this.$store.commit("settings/setSettings", this.settings);
  }

  toggleDrag() {
    this.$store.commit("toggleDrag");
  }

  reset() {
    this.$store.commit("settings/resetNums", this.$store.state.settings.game);
  }

  toggleEdit() {
    this.$store.commit("toggleEdit");
  }
}
